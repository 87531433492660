module.exports = {
  eventPermission: 'Events permissions are inherited from the process that they trigger.',
  gdprHint: 'Only active objects are included in the following lists.',
  noExceptionMessage: 'No Exception Message',
  noExceptionStackTrace: 'No Exception Stack Trace',
  dataTooLArge: 'The selected entry is too large to display. Would you like to store it as file?',
  cacheTooLarge: 'The cache is too large to display',
  pluginPresent: 'This module contains plugin. Only install if you trust the source of the module!',
  saveFirst: 'Save first to use this feature!',
  addKeyFirst: 'Please add Key before opening Editor!',
  slackMessage: 'Line breaks will be removed on save',
  noDebugLogData: 'No debug log data available',
  settingsChangedStoreToUpDownload: 'Settings have been changed. Please store module first to re-enable Up/Download.',
  ttlLogsHint: 'Setting the value to 0 means that logs will be cached indefinitely. It is highly recommended to set a different value.',
  subType: 'Object structure is defined by separate validation',
  restoreHint: 'Restore will create a new object with the name "Original Name (Restore)". Note that The original object name is in use and that you will not be able to save it as "Original Name".',
  storageHint: 'Storage permissions are inherited from the process that they are related to.',
  noRelationsToShow: 'No relations to show',
  saveToShow: 'Please store entity in order to enable this functionality',
  addAtLeastOneField: 'Please add at least one field to be able to save entity',
  noEntityFieldsMessage: 'No entity field to show at the moment',
  noPersistenceFieldsMessage: 'No persistence field to show at the moment',
  noConnectedResourcesToShow: 'No connected resources to show',
  noParentRelationsToShow: 'No parent relations to show',
  noChildRelationsToShow: 'No child relations to show',
  dataRefreshingEveryMinute: 'Data is refreshing every minute automatically',
  selectProcessToFetchLogs: 'Please select a process to fetch logs',
  unsavedTitle: 'Unsaved Changes',
  unsavedChanges: 'You have unsaved changes. Are you sure you want to leave?',
  processNameNoExists: 'Referenced process not found',
  resourceIsSystem: 'This resource is a system resource and cannot be edited',
  technicalDifficulty: 'We\'re  facing some technical issues at the moment. If this problem continues, please reach out to your system administrator for support.',
  requiredFilters: 'The following required fields are empty: ',
  searchingForConnectedResources: 'Please wait while we search for connected resources. In some cases this process may take several minutes. Thank you for your patience.',
  nonErrorLogsIndefiniteWarning: 'Warning: non-error logs are kept indefinitely',
  errorLogsIndefiniteWarning: 'Warning: error logs are kept indefinitely',
  logOutOfAllSessions: 'Log out all active sessions for this user.',
  generateNewApi: 'Generate a new API key for user integrations and enhanced access management.',
  accountSecurity: 'Manage user account security by resetting their password and failed login attempts.',
  apiModalDesc: 'Replace the old api key with this new one wherever its used, or use it for the first time.',
  persistenceGenerated: 'Persistence is generated.',
  timestampHint: 'Example value: 2021-01-01 00:00:00'
}
