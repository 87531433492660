/* eslint-disable indent */
import Vue from 'vue'
import {
  getUserNameUsingGET as getUserName,
  getProcessesUsingGET as getProcesses,
  createProcessUsingPOST as createProcess,
  getTextTemplateByIdUsingGET as getTemplate,
  getTextTemplateTranslationsUsingGET as getTranslations,
  getRolesUsingGET as getRoles
} from '@/utils/api'
import { format } from 'date-fns'
import router from '@/router'

export const arraysEqual = (arr1, arr2) => {
  if (arr1.length !== arr2.length) {
    return false
  }

  return arr1.every((value, index) => value === arr2[index])
}

export const objectsEqual = (obj1, obj2) => {
  if ((!obj1 || !obj2) || (obj1 && !obj2) || (!obj1 && obj2)) {
    return false
  }
  if (Object.keys(obj1).length !== Object.keys(obj2).length) {
    return false
  }

  return Object.keys(obj1).every((key) => obj1[key] === obj2[key])
}

export const safeJSONParse = (str) => {
  try {
    return JSON.parse(str)
  } catch (e) {
    return null
  }
}

export const predictBestTimeUnitFromMsValue = async (value) => {
  if (Number(value / 1000 / 60 / 60 / 24).toFixed() === String(value / 1000 / 60 / 60 / 24)) return { value: (value / 1000 / 60 / 60 / 24), unit: 'Day' }
  if (Number(value / 1000 / 60 / 60).toFixed() === String(value / 1000 / 60 / 60)) return { value: (value / 1000 / 60 / 60), unit: 'Hr' }
  if (Number(value / 1000 / 60).toFixed() === String(value / 1000 / 60)) return { value: (value / 1000 / 60), unit: 'Min' }
  if (Number(value / 1000).toFixed() === String(value / 1000)) return { value: (value / 1000), unit: 'Sec' }

  return { value: value, unit: 'MSec' }
}

export const predictBestSizeUnitFromBytesValue = async (value) => {
  if (Number(value / 1024 / 1024).toFixed() === String(value / 1024 / 1024)) return { value: (value / 1024 / 1024), unit: 'Megabytes' }
  if (Number(value / 1024).toFixed() === String(value / 1024)) return { value: (value / 1024), unit: 'Kilobytes' }

  return { value: value, unit: 'Bytes' }
}

export const fetchUserData = async (id) => {
  const data = await getUserName({ id })

  return { id, ...data.data.data }
}

export const copyCreateResource = (item, toScreen, router, type = '', dateTime, isRestore = false, idOnly = false) => {
  const tempItem = item

  if (!idOnly) delete tempItem.id
  delete tempItem.createdOn
  delete tempItem.modifiedOn
  if (!isRestore) delete tempItem.roles

  tempItem.name = isRestore ? `${item.name} - Restore - ${dateTime}` : `${item.name} - Copy - ${dateTime}`

  router.push({ name: toScreen, params: { copy: tempItem, type: type ? type : '' } })
}

export const copyCreateTemplate = async (item) => {
  try {
    const templateRes = await getTemplate({ templateId: item.id })

    const tempItem = templateRes.data.data

    const translationRes = await getTranslations({ templateId: item.id })

    const tempTranslations = translationRes.data.data.items.map((x) => {
      x.isEdit = true

      return x
    })

    delete tempItem.id
    delete tempItem.createdOn
    delete tempItem.modifiedOn
    delete tempItem.roles

    const dateTimeFilter = Vue.options.filters['formatDateTime']

    tempItem.name = `${item.name} - Copy - ${dateTimeFilter(new Date())}`
    router.push({ name: 'templateCreate', params: { copy: JSON.stringify({ tempItem, tempTranslations }) } })

    return true
  } catch (err) {
    return err
  }
}

export const restoreResourceFromHistory = (item, resourceType, toScreen, router, dateTime) => {
  const tempItem = item

  delete tempItem.id
  delete tempItem.createdOn
  delete tempItem.modifiedOn
  delete tempItem.roles

  tempItem.name = `${item.name} - Restore - ${dateTime}`

  const params = { restore: tempItem }

  if (resourceType === 'PROCESS_CREDENTIAL') {
    params.type = item.type.toLowerCase()
  }

  if (resourceType === 'SETTING') {
    switch (item.type) {
      case 'INSTANCE_PROCESSING':
        params.type = 'INSTANCE_PROCESSING'.toLowerCase().replace('-', '_')
        break
      case 'PROCESS':
        params.type = 'PROCESS'.toLowerCase().replace('-', '_')
        break
      case 'PLUGIN':
        params.type = 'PLUGIN'.toLowerCase().replace('-', '_')
        break
      case 'INSTANCE_ADMIN':
        params.type = 'INSTANCE_ADMIN'.toLowerCase().replace('-', '_')
        break
      case 'GLOBAL_SETTING':
        params.type = 'GLOBAL_SETTING'.toLowerCase().replace('-', '_')
        break
      case 'SETTING':
        params.type = 'SETTING'.toLowerCase().replace('-', '_')
        break
      default:
        break
    }
  }

  router.push({ name: toScreen, params })
}

const msToHMS = (ms) => {
  if (ms < 1001) return ms + ' ms'

  if (ms < 60000) return (ms / 1000).toFixed(2) + ' sec'

  if (ms < 3600000) return (ms / 1000 / 60).toFixed(2) + ' min'

  if (ms < 86400000) { // Less than a day
    const date = new Date(ms)
    const hours = date.getUTCHours()
    const minutes = date.getUTCMinutes()

    return (hours < 10 ? '0' : '') + hours + ':' + (minutes < 10 ? '0' : '') + minutes + ' HH:mm'
  } else { // More than a day
    const days = Math.floor(ms / 86400000)
    const hours = Math.floor((ms % 86400000) / 3600000)
    const minutes = Math.floor((ms % 3600000) / 60000)

    return `${days} day${days > 1 ? 's' : ''}, ${hours} hour${hours > 1 ? 's' : ''}, and ${minutes} minute${minutes > 1 ? 's' : ''}`
  }
}

export const calculateDuration = (startTime, endTime) => {

  if (!startTime || !endTime) return msToHMS(0)

  return msToHMS(new Date(endTime).valueOf() - new Date(startTime).valueOf())
}

export const roleChecker = (userRoles, rolesArray) => {
  return !(userRoles && userRoles.some((role) => rolesArray.includes(role.name)))
}

export const recursion = (arr, localStep) => {
  let stepValue = []
  let bool = false

  arr.forEach((item) => {
    const id = typeof localStep?.localId === 'string' ? localStep?.localId.split('.')[0] : localStep?.localId
    const itemId = typeof item?.localId === 'string' ? item?.localId.split('.')[0] : item?.localId

    if (itemId > id) {
      bool = true
    } else {
      bool = false
      if (localStep?.localId + '' >= item?.localId + '' && ('' + item.localId)?.split('.')[0] === ('' + localStep?.localId)?.split('.')[0]) {
        if (item.type === 'FOREACH') {
          stepValue.push({ 'key': item?.properties?.recordName })
        }
      }
    }

    if (item.properties?.set && !bool && (localStep?.localId + '' >= item?.localId + '' || +localStep?.localId > +item?.localId)) {
      for (const key in item?.properties?.set) {
        stepValue.push({
          key,
          localId: item.localId,
          value: item.properties.set[key],
          type: item.type
        })
      }
    } else if (item.type === 'FOREACH' && !bool) {
      if (item.properties?.steps.length) {
        const stepsArray = recursion(item.properties?.steps, stepValue)

        stepsArray.forEach((step) => {
          stepValue.push(step)
        })
      }
    } else if (item.type === 'UNSET_VARIABLES' && !bool) {
      stepValue = stepValue.filter((el) => {
        return !item?.properties?.variables.find((objFromB) => {
          return el.key === objFromB
        })
      })

    } else if ((item?.properties?.steps?.length > 0 || item?.steps?.length) && !bool) {
      let stepsArray = []

      if (item?.steps?.length) {
        stepsArray = recursion(item?.steps, stepValue)
      } else {
        stepsArray = recursion(item?.properties?.steps, stepValue)

      }

      stepsArray.forEach((step) => {
        stepValue.push(step)
      })
    } else if (item?.properties?.conditions?.length > 0 && !bool) {
      const stepsArray = recursion(item?.properties?.conditions, stepValue)

      stepsArray.forEach((step) => {
        stepValue.push(step)
      })
    }

    if ((item.type === 'JDBC' ||
      item.type === 'MONGODB' ||
      item.type === 'CSV' ||
      item.type === 'EXECUTE_EXTERNAL_COMMAND' ||
      item.type === 'IMAGE' ||
      item.type === 'JDBC' ||
      item.type === 'JWT' ||
      item.type === 'PDF' ||
      item.type === 'UUID') &&
      !bool) {
      if (item.properties?.targetObject)
        stepValue.push({ 'key': item.properties?.targetObject })
    } else if (item.type === 'USER') {
      if (item.properties?.fields?.targetObject)
        stepValue.push({ 'key': item.properties?.fields?.targetObject })
    } else if (item.type === 'REST' && !bool) {
      if (item.properties?.restResponseCode)
        stepValue.push({ 'key': item.properties?.restResponseCode })
      if (item.properties?.targetObject)
        stepValue.push({ 'key': item.properties?.targetObject })
    } else if (item.type === 'EXECUTE_PROCESS') {
      const inputKeys = item.properties?.input !== null ? Object.keys(item.properties?.input) : []
      const outputKeys = item.properties?.output !== null ? Object.keys(item.properties?.output) : []
      const inputOutputArr = [...inputKeys, ...outputKeys]

      inputOutputArr.forEach((key) => {
        stepValue.push({ 'key': key })
      })
    }
  })

  return stepValue
}

export const getAllStepsData = (processSteps, formatFunction = (step) => step) => {
  const allSteps = []

  const addStepWithInnerSteps = (stepData, isCatch = false) => {
    const step = isCatch
      ? { steps: [...stepData], name: 'catch', localId: 'catch' }
      : { ...stepData }

    allSteps.push(formatFunction(step))

    if (step.properties?.conditions?.length > 0) {
      step.properties?.conditions.forEach((condition) => {
        addStepWithInnerSteps(condition)
      })
    }

    const innerSteps = step.properties?.steps || step.steps || []

    if (innerSteps.length > 0) {
      innerSteps.forEach((subStep) => {
        addStepWithInnerSteps(subStep)
      })
    }

    if (step.type === 'TRY_CATCH') {
      step?.properties?.try && addStepWithInnerSteps(step.properties.try, false)
      step?.properties?.catch && addStepWithInnerSteps(step.properties.catch, true)
      step.properties?.finally && addStepWithInnerSteps(step.properties.finally, false)
    }
  }

  processSteps.forEach((step) => {
    addStepWithInnerSteps(step)
  })

  return allSteps
}

export const addDoubleQuotesIfString = (value) => {
  let formattedValue = ''

  switch (typeof value) {
    case 'string':
      if (value.startsWith('"') && value.endsWith('"')) {
        formattedValue = value

      } else if (isJavaVariable(value)) {
        formattedValue = value

      } else if (value.startsWith('"') || value.endsWith('"')) {
        formattedValue = `"${value.replace(/\\?"/g, '\\"')}"`

      } else {
        formattedValue = `"${value}"`
      }
      break
    case 'object':
      if (value !== null) {
        formattedValue = value
      } else {
        formattedValue = 'null'
      }
      break
    case 'NaN':
      formattedValue = 'NaN'
      break
    case 'undefined':
      formattedValue = 'undefined'
      break
    default:
      formattedValue = value
  }

  return formattedValue
}

export const removeAdditionalPropertiesFromSteps = (
  allSteps,
  onlyDeleteSelectionIndicatorsAndSimpleCleaning = false
) => {
  const localAllSteps = JSON.parse(JSON.stringify(allSteps))

  const searchStep = (items) => {
    items.forEach((item) => {

      if (item.subType === 'QUERY') {
        delete item.enabled
        delete item.properties
      }

      delete item.isSelected
      delete item.logStatus
      if (item.properties?.catch?.length) {

        searchStep(item.properties.catch)
        item.properties.catch.forEach((item2) => {
          delete item2.isSelected
          delete item2.id
          searchStep(item2.steps)
        })
      }
      if (item.properties?.try) {
        delete item.properties?.try?.isSelected
        delete item.properties?.try?.subType
        if (item.properties.try.steps.length > 0) {
          searchStep(item.properties.try.steps)
          item.properties.try.steps.forEach((item2) => {
            delete item2.isSelected
          })
        }
      }
      if (item.properties?.finally) {
        delete item.properties?.finally?.isSelected
        if (item.properties.finally.steps.length > 0) {
          searchStep(item.properties.finally.steps)
          item.properties.finally.steps.forEach((item2) => {
            delete item2.isSelected
          })
        }
      }
      if (item.properties?.conditions?.length) {
        searchStep(item.properties.conditions)
        item.properties.conditions.forEach((item2) => {
          delete item2.isSelected
          delete item2.id
          searchStep(item2.steps)
        })
      }
      if ((item.type === 'JS' || item.type === 'GROOVY' || item.type === 'PYTHON') &&
        (!item.librariesNames
          || (Array.isArray(item.librariesNames)
            && !item.librariesNames?.length))
      ) item.librariesNames = null

      // We need to add double quotes to input and output values
      // in original steps to avoid unexpected "Unsaved changed" modal
      if (item.type === 'EXECUTE_PROCESS') {
        //TODO DRY fors
        for (const key in item.properties.input) {
          const value = item.properties.input[key]

          const formattedValue = addDoubleQuotesIfString(value)

          item.properties.input[key] = formattedValue
        }
        for (const key in item.properties.output) {
          const value = item.properties.output[key]

          const formattedValue = addDoubleQuotesIfString(value)

          item.properties.output[key] = formattedValue
        }
      }

      for (const row in item.properties) {
        // eslint-disable-next-line no-prototype-builtins
        if (item.properties.hasOwnProperty(row)) {
          if (row !== 'steps' && !item.properties[row]) item[row] = item.properties[row] = null
          if (row === 'librariesNames') {
            if (
              !item.properties?.librariesNames
              || (Array.isArray(item.properties?.librariesNames)
                && !item.properties?.librariesNames?.length)
            ) item.properties.librariesNames = null
          }
          if (row === 'attachments') {
            if (!item.properties?.attachments || (Array.isArray(item.properties?.attachments) && !item.properties?.attachments?.length)) item.properties.attachments = null
          }
        }
      }
      if (
        item.properties &&
        item.properties.steps &&
        item.properties.steps.length > 0
      ) {
        searchStep(item.properties.steps)
      }
      if (onlyDeleteSelectionIndicatorsAndSimpleCleaning) {
        return
      }

      item.localId = item.localId ? item.localId.toString() : null

      delete item.authType

      delete item.librariesNames

      delete item.attachments

      if (item.properties?.pluginObj) delete item.properties.pluginObj

      if (item.properties?.librariesNames && item.properties?.librariesNames.length === 0) item.properties.librariesNames = null

      if (item.properties?.attachments && item.properties?.attachments.length === 0) item.properties.attachments = null

      if (item.type === 'REST' && item.properties?.authType === 'NONE') item.properties.authType = null

      if (item.type === 'REST' && item.properties?.timeout) item.properties.timeout = item.properties.timeout.toString()

      delete item.id
    })
  }

  searchStep(localAllSteps)

  return localAllSteps
}

export const doesProcessNameExist = async (processName) => (
  await getProcesses({ name: processName })
).data.data.items.find((x) => x.name === processName)

export const getProcessById = async (id) => {
  return new Promise((resolve, reject) => {
    getProcess({ id })
      .then((res) => {
        resolve(res.data.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const getProcessByName = async (name) => {
  return new Promise((resolve, reject) => {
    getProcesses({ request: { page: 1, size: 1, filter: { name } } })
      .then((res) => {
        const findCorrectProcess = res.data.data.items.find((x) => x.name === name)

        if (findCorrectProcess) {
          resolve(findCorrectProcess)
        } else {
          resolve(false)
        }
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const createProcessForTriggerData = async (triggerName, processNameExists = false, roles) => {
  const rolesWithoutAuth = await getRolesWithoutAuth()
  const rolesWithoutAuthIds = rolesWithoutAuth.map((role) => role.id)
  const rolesCleaned = roles.filter((role) =>
    rolesWithoutAuthIds.includes(role.roleId)
  )

  const res = await createProcess({
    body: {
      id: 0,
      createdOn: '',
      modifiedOn: '',
      name: processNameExists
        ? `${triggerName} - ${format(new Date(), 'yyyy-MM-dd HH:mm')}`
        : triggerName,
      status: 'ACTIVE',
      logsTtlInMSec: 86400000,
      errorsTtlInMSec: 86400000,
      maxProcessingDurationInMSec: 60000,
      maxSimultaneousExecutions: 1,
      overallSimultaneousExecutions: 1,
      simultaneousExecutionsPerInstance: 1,
      steps: {
        steps: [
          {
            enabled: true,
            id: 1,
            isSelected: false,
            name: 'Placeholder',
            properties: {
              message: 'Placeholder'
            },
            type: 'LOG',
            enableStepLog: true,
            enableCacheLog: true,
            localId: 1
          }
        ]
      },
      isGdprRelevant: false,
      roles: rolesCleaned,
      isCacheStepLogsEnabled: true,
      isStepLogsEnabled: true,
      comment: '',
      inputValidationRuleId: '',
      outputValidationRuleId: '',
      isSystem: false
    }
  })

  return res
}

export const canUserEditResource = (isSuperUser, editRole, isSystem) => {
  if (isSystem) return false

  if (isSuperUser) return true

  return editRole
}

export const filterUniqueObjectsByDataIdAndName = (value, index, self) => {
  return (
    self.findIndex(
      (obj) => obj.data.name === value.data.name && obj.data.id === value.data.id
    ) === index
  )
}

export const getRolesWithoutAuth = async () => {
  const roles = await getRoles()

  return roles.data.data.items.filter(
    (role) => role.name !== 'AUTHENTICATED' && role.name !== 'UNAUTHENTICATED'
  )
}

export const estimatedTimeOfExecution = (start, end) => {
  if (start && end) {
    const startInner = new Date(start)
    const endInner = new Date(end)
    const diff = endInner - startInner

    return msToHMS(diff)
  }

  return 'N/A'
}

export const checkSftpValues = (username, password, privateKey, passphrase) => {
  // Only username + password
  if (username && password && !privateKey && !passphrase) return true

  // Only username + private key
  if (username && privateKey && !password && !passphrase) return true

  // Only username + private key + passphrase
  if (username && privateKey && passphrase && !password) return true

  // Any other combination is invalid
  return false
}

export const isJavaVariable = (item) => {
  if (!item) return false
  if (typeof item !== 'string') return false

  return item.substring(0, 1) === '$' || item.substring(0, 2) === '$.'
}

export const formatDateTimeToISO = (date) => {
  if (!date) return ''

  // Format to 'YYYY-MM-DDTHH:mm:ss.sssZ'
  const isoString = new Date(date).toISOString()

  // Get the timezone offset in +00:00 format
  const timezoneOffset = -new Date().getTimezoneOffset()
  const sign = timezoneOffset >= 0 ? '+' : '-'
  const hoursOffset = Math.floor(Math.abs(timezoneOffset) / 60)
    .toString()
    .padStart(2, '0')
  const minutesOffset = (Math.abs(timezoneOffset) % 60)
    .toString()
    .padStart(2, '0')
  const offset = `${sign}${hoursOffset}:${minutesOffset}`

  // Combine date and time with offset and wrap in single quotes
  return `'${isoString.slice(0, -1)}${offset}'`
}

export const convertISOToDate = (isoString) => {
  if (!isoString) return ''
  // Remove the leading and trailing single quotes if present
  const cleanString = isoString.replace(/^'(.*)'$/, '$1')

  // Convert the cleaned ISO string to a Date object
  const date = new Date(cleanString)

  // Check if the date is valid
  if (isNaN(date.getTime())) {
    throw new Error('Invalid date format')
  }

  return date
}

export function setNestedProperty(obj, path, value, vueInstance) {
  const keys = path.split('.')
  const lastKey = keys.pop()
  const nestedObj = keys.reduce((acc, key) => {
    if (!acc[key]) vueInstance.$set(acc, key, {})

    return acc[key]
  }, obj)

  vueInstance.$set(nestedObj, lastKey, value)
}

export const getNestedValue = (obj, key) => {
  let nestedValue = obj[key]

  if (key.includes('.')) {
    const keys = key.split('.')

    keys.forEach((k) => {
      if (nestedValue) {
        nestedValue = nestedValue[k]
      }
    })
  }

  return nestedValue
}

export const getNestedPropertyForSetting = (obj, path) => {
  const keys = path.split('.')
  const lastKey = keys.pop()
  const nestedObj = keys.reduce((acc, key) => {
    if (!acc[key]) this.$set(acc, key, {})

    return acc[key]
  }, obj)

  return { nestedObj, lastKey }
}

export const deepMerge = (target, source) => {
  const output = { ...target }

  if (target && source) {

    Object.keys(source).forEach((key) => {
      const sourceVal = source[key]
      const targetVal = target[key]

      if (typeof sourceVal === 'object' && sourceVal && !Array.isArray(sourceVal)) {
        output[key] = targetVal && typeof targetVal === 'object'
          ? deepMerge(targetVal, sourceVal)
          : deepMerge({}, sourceVal)
      } else {
        output[key] = sourceVal
      }
    })
  }

  return output
}

export const checkEmail = (v) => {
  const isSplit = v.split(',')

  if (isSplit.length > 1) {
    let correct = true

    isSplit.forEach((testEmail) => {
      if (correct) {
        correct = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(testEmail.trim().toLowerCase())
      }
    })

    if (correct) return true

    return false
  } else {
    return (v && /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v.toLowerCase()))
  }
}

export const filterNumber = (evt) => {
  evt = (evt) ? evt : window.event
  const expect = evt.target.value.toString() + evt.key.toString()

  if (!/^[-+]?[0-9]+$/.test(expect)) {
    evt.preventDefault()

    return false
  } else {
    return true
  }
}

// Helper function to remove enclosing quotes from a value, but don't cast it further
const removeEnclosingQuotes = (value) => {
  const trimmedValue = value.trim()

  // Remove single or double quotes from start and end
  if (
    (trimmedValue.startsWith('"') && trimmedValue.endsWith('"')) ||
    (trimmedValue.startsWith('\'') && trimmedValue.endsWith('\''))
  ) {
    return trimmedValue.slice(1, -1)
  }

  return value
}

// Helper function to cast values based on their content
export const castValue = (value) => {
  if (typeof value === 'string') {
    // First, check if the value is enclosed in quotes, if so, remove quotes but don't cast
    const unquotedValue = removeEnclosingQuotes(value)

    // If the value was enclosed in quotes, return the unquoted value without further casting
    if (unquotedValue !== value) {
      return unquotedValue // This ensures we return 'true' as a string, not a boolean
    }

    // Check for numbers
    if (!isNaN(value) && value.trim() !== '') {
      return parseFloat(value)
    }

    // Check for booleans
    if (value.toLowerCase() === 'true') return true
    if (value.toLowerCase() === 'false') return false

    // Check for empty strings
    if (value.trim() === '') return null
  }

  // Return value for arrays and objects if stringified
  try {
    const parsed = JSON.parse(value)

    if (typeof parsed === 'object') {
      return parsed
    }
  } catch (e) {
    // It's not a stringified object/array, leave as is
  }

  // Return the original value if no other condition matches
  return value
}
export const castDefaultValueFromDataType = (value, type) => {
  const normalizedType = type.toLowerCase()

  switch (normalizedType) {
    case 'string':
    case 'varchar':
    case 'timestamp':
      return value === '' || value === null || value === undefined ? null : String(value)

    case 'number':
    case 'bigint':
    case 'integer':
    case 'double':
    case 'float':
      // Check if value is NaN to handle non-numeric strings

      return !isNaN(value) && value && String(value).trim() !== '' ? parseFloat(value)
        : String(value).trim() === '' ? null : value

    case 'boolean':
      // Convert value to boolean based on string contents
      return value === null ? null : String(value).toLowerCase() === 'true'

    case 'json':
      // Try to parse JSON; return null if invalid
      try {

        return JSON.parse(value)
      } catch (error) {

        return null // Return null if JSON parsing fails
      }

    default:
      return value // Return original if type not matched
  }
}
export const castValueForStep = (field, value, typesAndFieldsForCasting) => {

  value = value === undefined ? null : value

  if (typesAndFieldsForCasting.shouldBeString?.includes(field)) {
    return value !== null ? String(value) : null // Use null for unset values, cast to string otherwise
  }

  if (typesAndFieldsForCasting.shouldBeListOfString?.includes(field)) {
    if (Array.isArray(value)) {
      return value.map((item) => (item !== null ? String(item) : null))
    }

    return [] // Default to empty array
  }

  if (typesAndFieldsForCasting.shouldBeMap?.includes(field)) {
    if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
      return Object.fromEntries(
        Object.entries(value).map(([key, val]) => [key, val !== null ? String(val) : null])
      ) // Use null for unset map values
    }

    return {} // Default to empty map
  }

  if (typesAndFieldsForCasting.shouldBeMapOfStrings?.includes(field)) {
    if (typeof value === 'object' && value !== null) {
      const newValue = {}

      Object.keys(value).forEach((key) => {
        newValue[key] = value[key] !== null ? String(value[key]) : null // Use null for unset values
      })

      return newValue
    }

    return {} // Default to empty map
  }

  if (typesAndFieldsForCasting.shouldBeNumberOrString?.includes(field)) {
    // Try to parse as a number, fallback to string, or null if unset
    return value !== null && !isNaN(value) ? Number(value) : value !== null ? String(value) : null
  }

  return value // Return value as is if no typecasting is defined
}

export const castAllStepsValues = async (steps) => {
  const castedSteps = JSON.parse(JSON.stringify(steps))

  // Recursive function to cast each value deeply in an object or array
  const castDeep = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map(castDeep) // Apply casting recursively to arrays
    } else if (typeof obj === 'object' && obj !== null) {
      const newObj = {}

      Object.keys(obj).forEach((key) => {
        const value = obj[key]

        newObj[key] =
          typeof value === 'object' && value !== null ? castDeep(value) : castValue(value)
      })

      return newObj
    } else {
      return castValue(obj) // Cast simple values directly
    }
  }

  const replaceStepValues = (items) => {
    items.forEach((item) => {
      // Apply castDeep to each property that contains an object or value
      item.properties = item.type === 'EXECUTE_PROCESS' ? castDeep(item.properties) : item.properties

      // Recursively handle nested structures
      if (item.properties?.steps?.length > 0) {
        replaceStepValues(item.properties.steps)
      }
      if (item.properties?.try?.steps?.length > 0) {
        replaceStepValues(item.properties.try.steps)
      }
      if (item.properties?.finally?.steps?.length > 0) {
        replaceStepValues(item.properties.finally.steps)
      }
      if (item.properties?.catch?.length > 0) {
        item.properties.catch.forEach((item2) => {
          replaceStepValues(item2.steps)
        })
      }
      if (item.properties?.conditions?.length > 0) {
        item.properties.conditions.forEach((item2) => {
          replaceStepValues(item2.steps)
        })
      }
    })
  }

  await replaceStepValues(castedSteps) // Use await in case async operations are needed

  return castedSteps
}
