<template>
  <v-container style="padding-right: 18px !important; max-width: unset !important;">
    <v-row justify="start">
      <v-col cols="12">
        <div
          :id="`outer-${stepDepth}`"
          style="max-height: unset; overflow-y: auto; overflow-x: hidden"
          class="fill-height"
        >
          <v-timeline align-top dense style="overflow-y: hidden; overflow-x: hidden" class="mb-3">
            <draggable v-model="stepsCopy" handle=".handle">
              <transition-group>
                <template v-for="(itemOuter, i) in steps">
                  <add-row
                    v-if="i === 0"
                    :key="`${itemOuter.id}${itemOuter.localId}`"
                    :data-cy="`add-standard-${itemOuter.localId}-before`"
                    @click.native="canEdit ? addStep(itemOuter, false, false, '') : ''"
                  ></add-row>
                  <v-timeline-item
                    :id="itemOuter.localId"
                    :key="`${itemOuter.id}${itemOuter.localId}${i}`"
                    right
                    :class="{
                      'is-special-step': ['FOREACH', 'SWITCH', 'TRY_CATCH', 'WHILE', 'DO_WHILE'].includes(itemOuter.type),
                      'is-normal-step': !['FOREACH', 'SWITCH', 'TRY_CATCH', 'WHILE', 'DO_WHILE'].includes(itemOuter.type),
                      'is-failed': itemOuter.logStatus === 'FAILED',
                      'is-success': itemOuter.logStatus === 'SUCCESSFUL',
                      'is-step-caught': itemOuter.logStatus === 'CAUGHT',
                      'is-step-in-progress': itemOuter.logStatus === 'IN_PROGRESS'
                    }"
                  >
                    <template v-slot:icon>
                      <v-icon
                        v-if="['FOREACH', 'SWITCH', 'TRY_CATCH', 'WHILE', 'DO_WHILE'].includes(itemOuter.type)"
                        style="cursor: pointer; font-size: 26px; color: white !important;"
                        @click="$emit('showHideChildren', { item: itemOuter, hideChildren: !itemOuter.hideChildren })"
                      >
                        {{ itemOuter.hideChildren ? 'mdi-chevron-right' : 'mdi-chevron-down' }}
                      </v-icon>
                    </template>
                    <v-card flat :class="{ 'item-on-hover': true, 'is-selected': itemOuter.isSelected }">
                      <v-row dense no-gutters justify="space-between" align="center">
                        <div style="flex: 1" class="clickable-simple" @click="previewData(itemOuter, '', null, '', '')">
                          <v-card-title
                            v-if="itemOuter.name"
                            :id="`select-${itemOuter.localId}`"
                            style="display:inline-block; max-width: 800px;"
                            class="headline title-short"
                          >
                            <span :class="{'color-primary': true, 'not-enabled': !itemOuter.enabled }">{{
                              itemOuter.name
                            }}</span>
                          </v-card-title>
                          <v-card-text v-if="itemOuter.type" style="width: 100%; line-height: 1.2rem !important;">
                            <v-row dense no-gutters justify="space-between" align="center">
                              <div class="d-inline-flex"><span>{{
                                itemOuter.localId
                              }}:&nbsp;</span>{{ $lang.status[itemOuter.type] }}
                              </div>
                            </v-row>
                          </v-card-text>
                          <v-card-text v-if="itemOuter.query" style="line-height: 1.2rem !important;">{{
                            itemOuter.name || itemOuter.query
                          }}
                          </v-card-text>
                        </div>
                        <div class="d-inline-flex align-center">
                          <mover
                            :can-edit="canEdit"
                            :step-depth="stepDepth"
                            :step-length="steps.length"
                            :local-id="String(itemOuter.localId)"
                            @move="moveStep(itemOuter, $event)"
                          ></mover>
                          <step-context-menu
                            :item="itemOuter"
                            @showSnack="showSnack"
                            @duplicateStep="duplicateStep"
                            @pasteStep="pasteStep"
                            @pasteStepBefore="pasteStepBefore($event)"
                            @pasteStepAfter="pasteStepAfter($event)"
                            @deleteStep="deleteStep"
                          />
                        </div>
                      </v-row>
                    </v-card>
                    <v-divider v-if="['TRY_CATCH'].includes(itemOuter.type)" v-show="!itemOuter.hideChildren" class="mt-2 mb-1"/>
                    <add-row
                      v-if="['FOREACH', 'WHILE', 'DO_WHILE'].includes(itemOuter.type) && (!itemOuter.properties.steps || (itemOuter.properties.steps && itemOuter.properties.steps.length === 0))"
                      :key="`${itemOuter.id}${itemOuter.localId}add-inner-foreach-while${stepDepth}`"
                      :data-cy="`add-inner-${itemOuter.localId}`"
                      inner
                      @click.native="canEdit ? addStep(itemOuter, false, true) : ''"
                    ></add-row>
                    <add-row
                      v-if="itemOuter.type === 'SWITCH' && !itemOuter.properties.conditions"
                      :key="`${itemOuter.id}${itemOuter.localId}add-inner-switch${stepDepth}`"
                      :data-cy="`add-query-${itemOuter.localId}`"
                      special
                      @click.native="canEdit ? addStep(itemOuter, true) : ''"
                    ></add-row>
                    <div
                      v-if="itemOuter.properties && itemOuter.properties.steps && itemOuter.properties.steps.length > 0"
                      :key="`${itemOuter.id}${itemOuter.localId}${i}${stepDepth}`"
                      style="width: 100%; height: 100%"
                    >
                      <timeline
                        v-show="!itemOuter.hideChildren"
                        :can-edit="canEdit"
                        :step-depth="stepDepth + 1"
                        :steps="itemOuter.properties.steps"
                        :drag-lock="dragLock"
                        @previewData="previewData"
                        @addStep="addStep"
                        @moveStep="moveStep"
                        @deleteStep="deleteStep"
                        @showSnack="showSnack"
                        @pasteStep="pasteStep"
                        @pasteStepBefore="pasteStepBefore"
                        @pasteStepAfter="pasteStepAfter($event)"
                        @duplicateStep="duplicateStep"
                        @showHideChildren="showHideChildren"
                      ></timeline>
                    </div>
                    <div
                      v-show="!itemOuter.hideChildren"
                      v-if="itemOuter.properties && itemOuter.properties.conditions"
                      :key="`${itemOuter.id}${itemOuter.localId}${i + 1000}${stepDepth}`"
                      style="width: 100%; height: 100%"
                    >
                      <template v-for="(itemInner, index) in itemOuter.properties.conditions">
                        <v-timeline-item
                          :id="itemInner.localId"
                          :key="`${itemInner.id}${itemInner.localId}${i}${stepDepth}`"
                          right
                          :class="{
                            'pt-1': true,
                            'is-step-failed': itemInner.logStatus === 'FAILED',
                            'is-step-success': itemInner.logStatus === 'SUCCESSFUL',
                            'is-step-caught': itemInner.logStatus === 'CAUGHT',
                            'is-step-in-progress': itemInner.logStatus === 'IN_PROGRESS'
                          }"
                        >
                          <template v-slot:icon>
                            <v-icon
                              dark
                              style="cursor: pointer; font-size: 26px; color: white !important;"
                              @click="$emit('showHideChildren', { item: itemInner, hideChildren: !itemInner.hideChildren })"
                            >
                              {{ itemInner.hideChildren ? 'mdi-chevron-right' : 'mdi-chevron-down' }}
                            </v-icon>
                          </template>
                          <v-card flat :class="{ 'item-on-hover': true, 'is-selected': itemInner.isSelected }">
                            <v-row dense no-gutters justify="space-between" align="center">
                              <div style="flex: 1" class="clickable-simple" @click="previewData(itemInner, '', null, '', isTry)">
                                <v-card-title
                                  :id="`select-${itemInner.localId}`"
                                  class="headline clickable-simple"
                                ><span
                                  class="color-primary title-short"
                                >{{ $lang.labels.query }}: <small
                                  :class="`query-text-${$vuetify.theme.dark ? 'dark' : 'light'}`"
                                >{{ itemInner.name || itemInner.query }}</small></span>
                                </v-card-title>
                              </div>
                              <div class="d-inline-flex align-center">
                                <mover
                                  v-if="itemOuter.properties.conditions && itemOuter.properties.conditions.length > 1"
                                  is-exception
                                  :can-go-up="index > 0"
                                  :can-go-down="index < itemOuter.properties.conditions.length - 1"
                                  :can-edit="canEdit"
                                  :step-depth="stepDepth"
                                  :step-length="steps.length"
                                  :local-id="String(itemInner.localId)"
                                  @move="moveStep(itemInner, $event)"
                                ></mover>
                                <step-context-menu
                                  :item="itemInner"
                                  @showSnack="showSnack"
                                  @duplicateStep="duplicateStep"
                                  @pasteStep="pasteStep"
                                  @pasteStepBefore="pasteStepBefore($event)"
                                  @pasteStepAfter="pasteStepAfter($event)"
                                  @deleteStep="deleteStep"
                                />
                              </div>
                            </v-row>
                          </v-card>
                          <timeline
                            v-show="!itemInner.hideChildren"
                            :can-edit="canEdit"
                            :step-depth="stepDepth + 1"
                            :steps="itemInner.steps"
                            :drag-lock="dragLock"
                            @previewData="previewData"
                            @addStep="addStep"
                            @moveStep="moveStep"
                            @deleteStep="deleteStep"
                            @showSnack="showSnack"
                            @pasteStep="pasteStep"
                            @pasteStepBefore="pasteStepBefore"
                            @pasteStepAfter="pasteStepAfter($event)"
                            @duplicateStep="duplicateStep"
                            @showHideChildren="showHideChildren"
                          ></timeline>
                        </v-timeline-item>
                        <add-row
                          v-if="itemInner.steps.length === 0"
                          :key="`${itemInner.id}${itemInner.localId}add-standard${i}${stepDepth}`"
                          :data-cy="`add-standard-${itemInner.localId}`"
                          @click.native="canEdit ? addStep({ ...itemInner, id: -1 }) : ''"
                        ></add-row>
                        <add-row
                          v-if="index === itemOuter.properties.conditions.length - 1"
                          :key="index + (stepDepth * 2000)"
                          :data-cy="`add-query-${itemOuter.localId}`"
                          special
                          @click.native="canEdit ? addStep(itemInner, true) : ''"
                        ></add-row>
                      </template>
                    </div>
                    <v-card-title
                      v-if="['TRY_CATCH'].includes(itemOuter.type)"
                      v-show="!itemOuter.hideChildren"
                      class="headline clickable-simple"
                    >
                      Try
                    </v-card-title>
                    <add-row
                      v-if="['TRY_CATCH'].includes(itemOuter.type) && (!itemOuter.properties.try || (itemOuter.properties.try && itemOuter.properties.try.steps && itemOuter.properties.try.steps.length === 0))"
                      v-show="!itemOuter.hideChildren"
                      :key="`${itemOuter.id}${itemOuter.localId}add-inner-trycatch-${i}${stepDepth}`"
                      :data-cy="`add-inner-${itemOuter.localId}`"
                      inner
                      @click.native="canEdit ? addStep(itemOuter, false, true, '', 'try') : ''"
                    ></add-row>
                    <div
                      v-show="!itemOuter.hideChildren"
                      v-if="itemOuter.properties && itemOuter.properties.try && itemOuter.properties.try.steps && itemOuter.properties.try.steps.length > 0"
                      :key="`${itemOuter.id}${itemOuter.localId}${i}${stepDepth}`"
                      style="width: 100%; height: 100%"
                    >
                      <v-timeline-item
                        :id="itemOuter.localId"
                        :key="`${itemOuter.id}${itemOuter.localId}${i + 5000}${stepDepth}`"
                        right
                        class="pt-1"
                      >
                        <template v-slot:icon>
                          <v-icon
                            dark
                            style="cursor: pointer; font-size: 26px; color: white !important;"
                            @click="$emit('showHideChildren', { item: itemOuter, hideChildren: itemOuter.hideChildren, hideChildrenTry: !itemOuter.hideChildrenTry, hideChildrenFinally: itemOuter.hideChildrenFinally })"
                          >
                            {{ itemOuter.hideChildrenTry ? 'mdi-chevron-right' : 'mdi-chevron-down' }}
                          </v-icon>
                        </template>
                        <v-card v-show="!itemOuter.hideChildren" flat :class="{ 'item-on-hover': true, 'is-selected': itemOuter.properties.try.isSelected }">
                          <v-row
                            dense
                            no-gutters
                            justify="space-between"
                            align="center"
                            class="clickable-simple"
                            @click="previewData(itemOuter, '', null, '', 'try')"
                          >
                            <v-card-title
                              :id="`select-${itemOuter.localId}`"
                              class="headline clickable-simple"
                            ><span
                              class="color-primary title-short"
                            >{{ $lang.labels.try }}{{ itemOuter.properties.try.name ? ':' : '' }}</span><span
                              class="color-primary ml-1"
                            >{{ itemOuter.properties.try.name }}</span>
                            </v-card-title>
                          </v-row>
                        </v-card>
                        <timeline
                          v-show="!itemOuter.hideChildrenTry"
                          :can-edit="canEdit"
                          :step-depth="stepDepth + 1"
                          :steps="itemOuter.properties.try.steps"
                          :drag-lock="dragLock"
                          is-try="try"
                          @previewData="previewData"
                          @addStep="addStep"
                          @moveStep="moveStep"
                          @deleteStep="deleteStep"
                          @showSnack="showSnack"
                          @pasteStep="pasteStep"
                          @pasteStepBefore="pasteStepBefore"
                          @pasteStepAfter="pasteStepAfter($event)"
                          @duplicateStep="duplicateStep"
                          @showHideChildren="showHideChildren"
                        ></timeline>
                      </v-timeline-item>
                    </div>
                    <v-card-title
                      v-if="['TRY_CATCH'].includes(itemOuter.type)"
                      v-show="!itemOuter.hideChildren"
                      class="headline clickable-simple"
                    >
                      Catch
                    </v-card-title>
                    <add-row
                      v-if="itemOuter.type === 'TRY_CATCH' && (!itemOuter.properties.catch || (itemOuter.properties.catch && itemOuter.properties.catch.length === 0))"
                      v-show="!itemOuter.hideChildren"
                      :key="`${itemOuter.id}${itemOuter.localId}${i + 7000}${stepDepth}`"
                      :data-cy="`add-exception-${itemOuter.localId}`"
                      catch-type
                      @click.native="canEdit ? addStep(itemOuter, true, '', '', 'catch') : ''"
                    ></add-row>
                    <div
                      v-show="!itemOuter.hideChildren"
                      v-if="itemOuter.properties && itemOuter.properties.catch"
                      :key="`${itemOuter.id}${itemOuter.localId}catch${i + 5000}${stepDepth}`"
                      style="width: 100%; height: 100%"
                    >
                      <template v-for="(itemInner, index) in itemOuter.properties.catch">
                        <v-timeline-item
                          :id="itemInner.localId"
                          :key="itemInner.localId + index"
                          right
                          class="pt-1"
                        >
                          <template v-slot:icon>
                            <v-icon
                              dark
                              style="cursor: pointer; font-size: 26px; color: white !important;"
                              @click="$emit('showHideChildren', { item: itemInner, hideChildren: !itemInner.hideChildren })"
                            >
                              {{ itemInner.hideChildren ? 'mdi-chevron-right' : 'mdi-chevron-down' }}
                            </v-icon>
                          </template>
                          <v-card flat :class="{ 'item-on-hover': true, 'is-selected': itemInner.isSelected }">
                            <v-row dense no-gutters justify="space-between" align="center">
                              <div style="flex: 1" class="clickable-simple" @click="previewData(itemInner, '', null, '', 'catch')">
                                <v-card-title
                                  :id="`select-${itemInner.localId}`"
                                  class="headline clickable-simple"
                                ><span
                                  class="color-primary title-short"
                                >{{ $lang.labels.exception }}</span><span
                                  class="color-primary ml-1"
                                >{{ itemInner.name ? itemInner.name : itemInner.exceptions && itemInner.exceptions.length > 0 ? `(${itemInner.exceptions.join(', ')})` : '' }}</span>
                                </v-card-title>
                              </div>
                              <div class="d-inline-flex align-center">
                                <mover
                                  v-if="itemOuter.properties.catch && itemOuter.properties.catch.length > 1"
                                  is-exception
                                  :can-go-up="index > 0"
                                  :can-go-down="index < itemOuter.properties.catch.length - 1"
                                  :can-edit="canEdit"
                                  :step-depth="stepDepth"
                                  :step-length="steps.length"
                                  :local-id="String(itemInner.localId)"
                                  @move="moveStep(itemInner, $event)"
                                ></mover>
                                <step-context-menu
                                  :item="itemInner"
                                  @showSnack="showSnack"
                                  @duplicateStep="duplicateStep"
                                  @pasteStep="pasteStep"
                                  @pasteStepBefore="pasteStepBefore($event)"
                                  @pasteStepAfter="pasteStepAfter($event)"
                                  @deleteStep="deleteStep"
                                />
                              </div>
                            </v-row>
                          </v-card>
                          <timeline
                            v-show="!itemInner.hideChildren"
                            :can-edit="canEdit"
                            :step-depth="stepDepth + 1"
                            :steps="itemInner.steps"
                            :drag-lock="dragLock"
                            is-try="catch"
                            @previewData="previewData"
                            @addStep="addStep"
                            @moveStep="moveStep"
                            @deleteStep="deleteStep"
                            @showSnack="showSnack"
                            @pasteStep="pasteStep"
                            @pasteStepBefore="pasteStepBefore"
                            @pasteStepAfter="pasteStepAfter($event)"
                            @duplicateStep="duplicateStep"
                            @showHideChildren="showHideChildren"
                          ></timeline>
                        </v-timeline-item>
                        <add-row
                          v-if="itemInner.steps.length === 0"
                          :key="`${itemInner.id}${itemInner.localId}add-standard${i + 9000}${stepDepth}`"
                          :data-cy="`add-standard-${itemInner.localId}`"
                          @click.native="canEdit ? addStep({ ...itemInner, id: -1 }, false, '', '', 'catch') : ''"
                        ></add-row>
                        <add-row
                          v-if="index === itemOuter.properties.catch.length - 1"
                          :key="index + (stepDepth * 2000)"
                          :data-cy="`add-exception-${itemOuter.localId}`"
                          catch-type
                          @click.native="canEdit ? addStep(itemInner, true, '', '', 'catch') : ''"
                        ></add-row>
                      </template>
                    </div>
                    <v-card-title
                      v-if="['TRY_CATCH'].includes(itemOuter.type)"
                      v-show="!itemOuter.hideChildren"
                      class="headline clickable-simple"
                    >
                      Finally
                    </v-card-title>
                    <add-row
                      v-if="['TRY_CATCH'].includes(itemOuter.type) && (!itemOuter.properties.finally || (itemOuter.properties.finally && itemOuter.properties.finally.steps && itemOuter.properties.finally.steps.length === 0))"
                      v-show="!itemOuter.hideChildren"
                      :key="`${itemOuter.id}${itemOuter.localId}add-inner-try-catch-2-${i}${stepDepth}`"
                      :data-cy="`add-inner-${itemOuter.localId}`"
                      inner
                      @click.native="canEdit ? addStep(itemOuter, false, true, '', 'finally') : ''"
                    ></add-row>
                    <div
                      v-show="!itemOuter.hideChildren"
                      v-if="itemOuter.properties && itemOuter.properties.finally && itemOuter.properties.finally.steps && itemOuter.properties.finally.steps.length > 0"
                      :key="`${itemOuter.id}${itemOuter.localId}${i}${stepDepth + 3333}`"
                      style="width: 100%; height: 100%"
                    >
                      <v-timeline-item
                        :id="itemOuter.localId"
                        :key="`${itemOuter.id}${itemOuter.localId}${i + 8000}${stepDepth + 1234}`"
                        right
                        class="pt-1"
                      >
                        <template v-slot:icon>
                          <v-icon
                            dark
                            style="cursor: pointer; font-size: 26px; color: white !important;"
                            @click="$emit('showHideChildren', { item: itemOuter, hideChildren: itemOuter.hideChildren, hideChildrenTry: itemOuter.hideChildrenTry, hideChildrenFinally: !itemOuter.hideChildrenFinally })"
                          >
                            {{ itemOuter.hideChildrenFinally ? 'mdi-chevron-right' : 'mdi-chevron-down' }}
                          </v-icon>
                        </template>
                        <v-card v-show="!itemOuter.hideChildren" flat :class="{ 'item-on-hover': true, 'is-selected': itemOuter.properties.finally.isSelected }">
                          <v-row
                            dense
                            no-gutters
                            justify="space-between"
                            align="center"
                            class="clickable-simple"
                            @click="previewData(itemOuter, '', null, '', 'finally')"
                          >
                            <v-card-title
                              :id="`select-${itemOuter.localId}`"
                              class="headline"
                            ><span
                              class="color-primary title-short"
                            >{{ $lang.labels.finally }}{{ itemOuter.properties.finally.name ? ':' : '' }}</span><span
                              class="color-primary ml-1"
                            >{{ itemOuter.properties.finally.name }}</span>
                            </v-card-title>
                          </v-row>
                        </v-card>
                        <timeline
                          v-show="!itemOuter.hideChildrenFinally"
                          :can-edit="canEdit"
                          :step-depth="stepDepth + 1"
                          :steps="itemOuter.properties.finally.steps"
                          :drag-lock="dragLock"
                          is-try="finally"
                          @previewData="previewData"
                          @addStep="addStep"
                          @moveStep="moveStep"
                          @deleteStep="deleteStep"
                          @showSnack="showSnack"
                          @pasteStep="pasteStep"
                          @pasteStepBefore="pasteStepBefore"
                          @pasteStepAfter="pasteStepAfter($event)"
                          @duplicateStep="duplicateStep"
                          @showHideChildren="showHideChildren"
                        ></timeline>
                      </v-timeline-item>
                    </div>
                  </v-timeline-item>
                  <add-row
                    :key="`${itemOuter.id}${itemOuter.localId}add-standard${i + 11000}${stepDepth}`"
                    :data-cy="`add-standard-${itemOuter.localId}`"
                    @click.native="canEdit ? addStep(itemOuter, false, false, true, isTry) : ''"
                  ></add-row>
                </template>
              </transition-group>
            </draggable>
            <add-row
              v-if="stepDepth === 1 && steps.length === 0"
              :data-cy="`add-standard-${itemOuter.localId}`"
              @click.native="canEdit ? addStep({ id: 0 }) : ''"
            ></add-row>
          </v-timeline>
        </div>
      </v-col>
    </v-row>
  </v-container>

</template>
<script>
import draggable from 'vuedraggable'
import mover from './Mover'
import addRow from './AddRow'
import copy from 'copy-to-clipboard'
import stepContextMenu from './StepContextMenu'

export default {
  name: 'ProcessTimeline',
  components: {
    draggable,
    mover,
    addRow,
    stepContextMenu
  },
  props: {
    steps: {
      type: Array,
      default: () => {
        return []
      }
    },
    stepDepth: {
      type: Number,
      default: () => {
        return 1
      }
    },
    canEdit: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    dragLock: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    isTry: {
      type: String,
      default: () => {
        return ''
      }
    }
  },
  data: () => ({
    stepsCopy: []
  }),
  methods: {
    linkToStep(item) {
      if (item && item.localId) {
        const fullPath = window.location.href.split('#')[0] + `#${item.localId}`

        window.location.hash = `${item.localId}`

        copy(fullPath)
      }
    },
    async addStep(beforeItem, isSpecial = false, innerStep = false, isAfter = false, tryCatch = '') {
      if (beforeItem && beforeItem.beforeItem) {
        this.$emit('addStep', {
          beforeItem: beforeItem.beforeItem,
          isSpecial: beforeItem.isSpecial,
          innerStep: beforeItem.innerStep,
          isAfter: beforeItem.isAfter,
          tryCatch: beforeItem.tryCatch
        })
      } else {
        this.$emit('addStep', { beforeItem, isSpecial, innerStep, isAfter, tryCatch })
      }
    },
    moveStep(item, direction) {
      if (item && item.item) {
        this.$emit('moveStep', { item: item.item, direction: item.direction })
      } else {
        this.$emit('moveStep', { item, direction })
      }
    },
    previewData(item, isSpecial = false, innerStep = false, isAfter = false, tryCatch = '') {
      if (item && item.item) {
        this.$emit('previewData', {
          item: item.item,
          isSpecial: item.isSpecial,
          innerStep: item.innerStep,
          isAfter: item.isAfter,
          tryCatch: item.tryCatch
        })
      } else {
        this.$emit('previewData', { item, isSpecial, innerStep, isAfter, tryCatch })
      }
    },
    deleteStep(item) {
      this.$emit('deleteStep', item)
    },
    showSnack(text) {
      this.$emit('showSnack', text)
    },
    pasteStep(item) {
      this.$emit('pasteStep', item)
    },
    pasteStepBefore(item) {
      this.$emit('pasteStepBefore', item)
    },
    pasteStepAfter(item) {
      this.$emit('pasteStepAfter', item)
    },
    duplicateStep(item) {
      this.$emit('duplicateStep', item)
    },
    showHideChildren(item) {
      this.$emit('showHideChildren', item)
    },
    dragSteps() {
      this.$emit('dragSteps', this.stepsCopy)
    }
  }
}
</script>
<style lang="scss">

</style>
